import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { changeState, commonModalSetting } from 'JS/common';
import CommonModal from '../CommonModal';

const AddAmountModal = ({ setModal }) => {
  const [alert, setAlert] = useState('');
  const [alertBox, setAlertBox] = useState({
    mode: '',
    context: '',
    bool: false,
  });

  const navigate = useNavigate();

  return (
    <>
      <div className='modal-background'>
        <div className='add-amount modal'>
          <div className='topBar'>
            <h3>수동 결제 추가</h3>
          </div>
          <div className='column input-wrap'>
            <input type='text' placeholder='비용명' />
            <input type='text' placeholder='금액' />
          </div>
          <div className='btnWrap'>
            <button>추가</button>
            <button
              onClick={() => {
                setAlert('cancel');
                commonModalSetting(
                  setAlertBox,
                  true,
                  'confirm',
                  '정말 취소하시겠습니까?<br/>지금까지 작성된 내용은 반영되지 않습니다.'
                );
              }}>
              취소
            </button>
          </div>
        </div>
      </div>
      {alertBox.bool && (
        <CommonModal
          setModal={setAlertBox}
          modal={alertBox}
          okFn={() => {
            if (alert === 'cancel') changeState(setModal, 'addAmount', false);
            else if (alert === 'logout') navigate('/');
            else return;
          }}
        />
      )}
    </>
  );
};

export default AddAmountModal;
