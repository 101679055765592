import 'App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import SignIn from 'Pages/SignIn';
import Home from 'Pages/Home';
import Notice from 'Pages/Notice';
import Manage from 'Pages/Manage';
import AdminManage from 'Pages/AdminManage';
import EventManage from 'Pages/EventManage';
import Inquiry from 'Pages/Inquiry';
import PopUp from 'Pages/PopUp';
import Application from 'Pages/Agencies';
import ApplicationDetail from 'Pages/AgenciesDetail';
import SelfApplication from 'Pages/SelfApplication';
import ErrorPage from 'Pages/ErrorPage';
import { useEffect } from 'react';
import SelfApplicationDetail from 'Pages/SelfApplicationDetail';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, [pathname]);

  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<SignIn />} />
        <Route path='/home' element={<Home />} />
        <Route path='/manage' element={<Manage />} />
        <Route path='/admin-manage' element={<AdminManage />} />
        <Route path='/self-application' element={<SelfApplication />} />
        <Route
          path='/self-application/:id'
          element={<SelfApplicationDetail />}
        />
        <Route path='/agencies' element={<Application />} />
        <Route path='/agencies/:id' element={<ApplicationDetail />} />
        <Route path='/inquiry' element={<Inquiry />} />
        <Route path='/notice' element={<Notice />} />
        <Route path='/popup' element={<PopUp />} />
        <Route path='/event-manage' element={<EventManage />} />
        <Route path='*' element={<ErrorPage />} />
      </Routes>
    </div>
  );
}

export default App;
