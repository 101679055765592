import axios from 'axios';
import { getCookie, setCookie, removeCookie } from './cookie';

const header = () => ({
  headers: {
    'Content-Type': 'application/json',
    'access-token': getCookie('pickAdminMyToken'),
  },
});

const formDataHeader = () => ({
  headers: {
    'Content-Type': 'multipart/form-data',
    'access-token': getCookie('pickAdminMyToken'),
  },
});

//~ 토큰 재발급
const tokenReissue = async () => {
  try {
    const result = await axios.get(
      `/v1/admin-page/auth/admin/new-token?refresh_toke=${getCookie(
        'pickAdminRfToken'
      )}`
    );
    removeCookie('pickAdminMyToken');
    setCookie('pickAdminMyToken', result.data.data.access_token, {
      path: '/',
    });
    window.location.reload();
  } catch (error) {
    return await errorHandling(error);
  }
};

//~ API Error Handling Function
const errorHandling = async error => {
  const { status } = error?.response;
  const { detail } = error?.response.data;
  switch (status) {
    case 401:
      if (detail === 'Access Denied') return 'accessDenied';
      else if (detail === 'Please Add Days') return 'addDays';
      else if (detail === 'WrongID') return 'wrongId';
      break;
    case 403:
      if (detail === 'Access Token Expired') return await tokenReissue();
      else if (detail === `Incorrect Password`) return `wrongPw`;
      else if (detail === 'LoginRequired') return 'tokenExpired';
      else if (detail === 'DuplicateLoginDetection') return 'duplicateLogin';
      else if (detail === 'Days Limit Exceeded') return 'datePasses';
      else if (detail === 'Expiration Date Exceeds limit')
        return 'exceedCharOfDate';
      else if (detail === 'Duplicated Id') return 'duplicateId';
      else if (detail === 'Retired User') return 'retired';
      break;
    case 499:
      return 'tokenError';
    case 500:
      return 'serverError';
    case 404:
      return 'notFound';
    case 501:
      if (detail === 'IntegrityError') return 'integrityError';
      break;
    case 504:
      return 'renderErrorPage';
    default:
      return;
  }
};

//= 로그인
export const signIn = async (userId, userPw) => {
  try {
    return await axios.post(`/v1/admin-page/auth/login`, {
      user_id: userId,
      origin_password: userPw,
    });
  } catch (error) {
    return await errorHandling(error);
  }
};

//= 전체 회원
export const getUserCount = async target => {
  try {
    return await axios.get(
      `/v1/admin-page/auth/user/count?filter_type=${target}`,
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//= 출원 대행 카운트
export const getApplicationCount = async () => {
  try {
    return await axios.get(`/v1/admin-page/agency/dashboard`, header());
  } catch (error) {
    return await errorHandling(error);
  }
};

//= 셀프 출원 카운트
export const getSelfApplicationCount = async () => {
  try {
    return await axios.get(`/v1/admin-page/self/dashboard`, header());
  } catch (error) {
    return await errorHandling(error);
  }
};

//! 회원 리스트 조회
export const getUserList = async ({ page, limit }, filter_type, filter_val) => {
  try {
    return await axios.get(
      `/v1/admin-page/auth/user/accounts?page=${page}&limit=${limit}&filter_type=${filter_type}&filter_val=${filter_val}`,
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//! 회원 정보 상세보기
export const getUserInfo = async userId => {
  try {
    return await axios.get(`/v1/admin-page/auth/user/info/${userId}`, header());
  } catch (error) {
    return await errorHandling();
  }
};

//# 관리자 리스트 조회
export const getAdmin = async ({ page, limit }, filter_type, filter_val) => {
  try {
    return await axios.get(
      `/v1/admin-page/auth/admin/accounts?page=${page}&limit=${limit}&filter_type=${filter_type}&filter_val=${filter_val}`,
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//# 관리자 정보 상세 보기
export const getAdminDetail = async user_id => {
  try {
    return await axios.get(
      `/v1/admin-page/auth/admin/info/${user_id}`,
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//# 관리자 등록
export const addAdmin = async query => {
  try {
    return await axios.post(
      `/v1/admin-page/auth/admin/signup`,
      query,
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//# 관리자 수정
export const editAdmin = async (user_id, query) => {
  try {
    return await axios.post(
      `/v1/admin-page/auth/admin/modify/${user_id}`,
      query,
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//# 관리자 아이디 중복 체크
export const idDuplicateCheck = async user_id => {
  try {
    return await axios.get(
      `/v1/admin-page/auth/admin/check/id-duplicates?user_id=${user_id}`,
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//# 관리자 단일 삭제
export const adminDelete = async deleteId => {
  try {
    return await axios.post(
      `/v1/admin-page/auth/admin/delete`,
      { user_pk: [deleteId] },
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//# 관리자 다중 삭제
export const adminMultiDelete = async deleteIdArr => {
  try {
    return await axios.post(
      `/v1/admin-page/auth/admin/delete`,
      {
        user_pk: deleteIdArr,
      },
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//& 공지사항 리스트 조회
export const getNoticeList = async (page, limit) => {
  try {
    return await axios.get(
      `/v1/cs/notice?page=${page}&limit=${limit}`,
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//& 공지사항 상세보기
export const getNoticeDetail = async noticeId => {
  try {
    return await axios.get(`/v1/admin-page/cs/notice/${noticeId}`, header());
  } catch (error) {
    return await errorHandling(error);
  }
};

//& 공지사항 단일 삭제
export const noticeDelete = async noticeId => {
  try {
    return await axios.delete(`/v1/admin-page/cs/notice`, {
      headers: {
        ...header(),
        'access-token': getCookie('pickAdminMyToken'),
      },
      data: {
        notice_ids: [noticeId],
      },
    });
  } catch (error) {
    return await errorHandling(error);
  }
};

//& 공지사항 다중 삭제
export const noticeMultiDelete = async noticeId => {
  try {
    return await axios.delete(`/v1/admin-page/cs/notice`, {
      headers: {
        ...header(),
        'access-token': getCookie('pickAdminMyToken'),
      },
      data: {
        notice_ids: noticeId,
      },
    });
  } catch (error) {
    return await errorHandling(error);
  }
};

//& 공지사항 수정하기
export const noticeEdit = async (notice, data) => {
  try {
    return await axios.put(
      `/v1/admin-page/cs/notice/edit/${notice}`,
      {
        title: data.title,
        context: data.context,
      },
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//& 공지사항 작성하기
export const noticeWrite = async info => {
  try {
    return await axios.post(
      `/v1/admin-page/cs/notice`,
      {
        title: info.title,
        context: info.context,
        user_pk: 1,
      },
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//@ 문의 사항 불러오기
export const getInquiryList = async (page, limit, select) => {
  try {
    return await axios.get(
      `/v1/admin-page/cs/inquiry?page=${page}&limit=${limit}&answer=${select}`,
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//@ 문의 사항 상세 조회
export const getInquiryDetail = async inquiryId => {
  try {
    return await axios.get(`/v1/admin-page/cs/inquiry/${inquiryId}`, header());
  } catch (error) {
    return await errorHandling(error);
  }
};

//@ 문의 사항 답변 등록
export const answerHandling = async (inquiryId, data) => {
  try {
    return await axios.put(
      `/v1/admin-page/cs/inquiry/answer/${inquiryId}`,
      data,
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//- 팝업 리스트 불러오기
export const getPopupList = async pageInfo => {
  try {
    return await axios.get(
      `/v1/admin-page/cs/popup?page=${pageInfo.page}&limit=${pageInfo.limit}`,
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//- 팝업 이미지 업로드
export const uploadPopupImage = async formData => {
  try {
    return await axios.post(
      `/v1/admin-page/cs/popup/upload-file`,
      formData,
      formDataHeader()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//- 팝업 생성
export const createPopup = async info => {
  try {
    return await axios.post(`/v1/admin-page/cs/popup`, info, header());
  } catch (error) {
    return await errorHandling(error);
  }
};

//- 팝업 수정
export const editPopup = async (id, data) => {
  try {
    return await axios.put(
      `/v1/admin-page/cs/popup/edit/${id}`,
      data,
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//- 팝업 삭제
export const deletePopup = async id => {
  try {
    return await axios.delete(`/v1/admin-page/cs/popup`, {
      headers: {
        ...header(),
        'access-token': getCookie('pickAdminMyToken'),
      },
      data: {
        popup_ids: id,
      },
    });
  } catch (error) {
    return await errorHandling(error);
  }
};

//~ 출원 대행 리스트
export const getAgenciesList = async (
  { page, limit },
  {
    markType,
    progress,
    applicantType,
    applicantName,
    startDate,
    endDate,
    markName,
    sort,
  }
) => {
  try {
    return await axios.get(
      `/v1/admin-page/agency/list?mark_type=${markType}&progress_status=${progress}&applicant_type=${applicantType}&sort_order=${sort}&page=${page}&limit=${limit}${
        startDate ? `&application_start_date=${startDate}` : ''
      }${endDate ? `&application_end_date=${endDate}` : ''}${
        markName ? `&mark_name=${markName}` : ''
      }${applicantName ? `&applicant=${applicantName}` : ''}`,
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//~ 출원 대행 상세 조회
export const getAgenciesDetail = async id => {
  try {
    return await axios.get(
      `/v1/admin-page/agency/detail?apply_agent_pk=${id}`,
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//~ 출원 대행 상세 - 상표명 수정
export const editMarkName = async (id, data) => {
  try {
    return await axios.post(
      `/v1/admin-page/agency/mark-name?apply_agent_pk=${id}`,
      data,
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//~ 출원 대행 상세 - 진행 상태 수정
export const editProgress = async (id, progress) => {
  try {
    return await axios.post(
      `/v1/admin-page/agency/status?apply_agent_pk=${id}`,
      { progress_status: progress },
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//~ 출원 대행 상세 - 상표 유형 수정
export const editMarkType = async (id, type) => {
  try {
    return await axios.post(
      `/v1/admin-page/agency/mark-type?apply_agent_pk=${id}`,
      { mark_type: type },
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//~ 출원 대행 상세 - 출원 번호 등록
export const applyApplicationNumber = async (id, { first, middle, last }) => {
  try {
    return await axios.post(
      `/v1/admin-page/agency/application-number?apply_agent_pk=${id}`,
      { application_number: `${first}-${middle}-${last}` },
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//~ 출원 대행 상세 - 출원 정보 가져오기
export const getApplicationInfo = async id => {
  try {
    return await axios.get(
      `/v1/admin-page/agency/application-information?apply_agent_pk=${id}`,
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//~ 출원 대행 상세 - 상품/업종 추가
export const applyProduct = async data => {
  try {
    return await axios.post(
      `/v1/admin-page/agency/add-product`,
      data,
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//~ 출원 대행 상세 - 상품/업종 삭제
export const deleteProduct = async data => {
  try {
    return await axios.post(
      `/v1/admin-page/agency/delete-product`,
      data,
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//~ 출원 대행 상세 - 임시 결제 리스트
export const getPaymentTemp = async id => {
  try {
    return await axios.get(
      `/v1/admin-page/agency/payment-temp?apply_agent_pk=${id}`,
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//* 셀프 출원 관리 리스트 조회
export const getSelfList = async (
  { page, limit },
  { markName, templateName, applicantName, startDate, endDate, markType }
) => {
  try {
    return await axios.get(
      `/v1/admin-page/self?page=${page}&limit=${limit}${
        markName ? `&mark_name=${markName}` : ''
      }${applicantName ? `&applicant_name=${applicantName}` : ''}${
        templateName ? `&template_name=${templateName}` : ''
      }${startDate ? `&start=${startDate?.replaceAll('-', '')}` : ''}${
        endDate ? `&end=${endDate?.replaceAll('-', '')}` : ''
      }${markType !== 'all' ? `&mark_type=${markType?.toUpperCase()}` : ''}`,
      header()
    );
  } catch (error) {
    return await errorHandling(error);
  }
};

//* 셀프 출원 관리 상세 조회
export const getSelfDetail = async id => {
  try {
    return await axios.get(`/v1/admin-page/self/${id}`, header());
  } catch (error) {
    return await errorHandling(error);
  }
};
