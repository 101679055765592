export const errorList = {
  accessDenied: '접근 권한이 없습니다.',
  tokenExpired: '토큰이 만료되었습니다.\n다시 로그인해 주세요.',
  retired: '탈퇴된 아이디입니다.',
  tokenError: '토큰이 만료되었습니다.\n다시 로그인해 주세요.',
  serverError: '잠시 후 다시 시도해 주세요.',
  duplicateLogin: '중복 로그인 되었습니다.\n다시 로그인해 주세요.',
  refresh: '새로 고침 후 다시 시도해 주세요.',
};

export const applicantType = {
  dom_indiv: '국내개인',
  dom_corp: '국내법인',
  fgn_indiv: '외국개인',
  fgn_corp: '외국법인',
  dom_inst: '국가기관',
};

export const statusList = {
  FilingReq: '출원 의뢰',
  FilingReqComp: '출원 의뢰 완료',
  FilingRecv: '출원 의뢰 접수 완료',
  AppProcessing: '출원 완료 (심사 중)',
  GenOpinion: '의견제출통지서 접수 (일반의견대응)',
  ExpOpinion: '의견제출통지서 접수 (전문의견대응)',
  SpecOpinion: '의견제출통지서 접수 (특수대응)',
  GenExpOpinion: '의견제출통지서 접수 (일반+전문의견대응)',
  GenSpecOpinion: '의견제출통지서 접수 (일반+특수대응)',
  OpnSubmitted: '의견서 제출 완료',
  PubApproved: '출원 공고 결정 (심사 합격)',
  OppRecv: '이의 신청서 접수',
  OppOngoing: '이의 신청 심리 중',
  RegDecRecv: '등록 결정서 접수',
  RegReqRecv: '등록 진행 의뢰 접수',
  RegAbandon: '등록 포기',
  RegComplete: '등록 완료',
  RefusalRecv: '거절 결정서 접수',
  TrialProc: '심판 진행 중',
  InvestCancel: '조사 후 취소',
  FilingCancel: '출원 후 취소 (30일 이내)',
  CancelAll: '전체 취소',
  Renewal: '갱신',
};

export const simpleStatusList = {
  FilingReq: '출원 의뢰',
  FilingReqComp: '출원 의뢰 완료',
  FilingRecv: '출원 의뢰 접수 완료',
  AppProcessing: '출원 완료 (심사 중)',
  PubApproved: '출원 공고 결정 (심사 합격)',
  RegDecRecv: '등록 결정서 접수',
  RegComplete: '등록 완료',
  CancelAll: '전체 취소',
};

export const markTypeList = {
  TEXT: '문자',
  FIGURE: '도형',
  COMPOUND: '복합',
};

export const paidStatusList = {
  ready: '결제 대기',
  paid: '결제 완료',
  failed: '결제 실패',
  cancelled: '결제 취소',
};
