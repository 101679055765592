import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineMoodBad } from 'react-icons/md';
import SideBar from 'Components/SideBar';
import NoticeDetail from 'Components/NoticeDetail';
import InquiryDetail from 'Components/InquiryDetail';
import NoticeWrite from 'Components/NoticeWrite';
import CommonModal from 'Components/CommonModal';
import { catchError } from 'JS/common';
import { markTypeList } from 'JS/array';
import {
  getUserCount,
  getNoticeList,
  getInquiryList,
  getApplicationCount,
  getSelfApplicationCount,
} from 'JS/API';

const Home = () => {
  //= 유저 카운트
  const [newUser, setNewUser] = useState('');
  const [allUser, setAllUser] = useState('');
  //= 셀프 출원 카운트
  const [selfList, setSelfList] = useState([]);
  const [selfCount, setSelfCount] = useState({});
  //= 출원 대행 카운트
  const [applicationList, setApplicationList] = useState([]);
  const [applicationCount, setApplicationCount] = useState({});

  const [noticeId, setNoticeId] = useState('');
  const [inquiryId, setInquiryId] = useState('');

  const [state, setState] = useState('');
  const [recentNotice, setRecentNotice] = useState([]);
  const [recentInquiry, setRecentInquiry] = useState([]);

  const [noticeModal, setNoticeModal] = useState(false);
  const [inquiryModal, setInquiryModal] = useState(false);
  const [editor, setEditor] = useState(false);

  const [alert, setAlert] = useState('');
  const [alertBox, setAlertBox] = useState({
    mode: '',
    context: '',
    bool: false,
  });

  const navigate = useNavigate();
  let prevent = false;

  //@ 6
  const getInquiry = async () => {
    const result = await getInquiryList(1, 5, 'notanswer');
    if (typeof result !== 'object')
      return catchError(result, navigate, setAlertBox, setAlert);
    const arr = [];
    for (let obj of result.data.data) arr.push(obj);
    setRecentInquiry(arr);
  };

  //@ 5
  const getNotice = async () => {
    const result = await getNoticeList(1, 5);
    if (typeof result !== 'object')
      return catchError(result, navigate, setAlertBox, setAlert);
    const arr = [];
    for (let obj of result.data.data) arr.push(obj);
    setRecentNotice(arr);
    getInquiry();
  };

  //@ 4
  const getSelfApplication = async () => {
    const result = await getSelfApplicationCount();
    if (typeof result !== 'object')
      return catchError(result, navigate, setAlertBox, setAlert);
    setSelfCount(result?.data?.info);
    setSelfList(result?.data?.content);
    getNotice();
  };

  //@ 3
  const getApplication = async () => {
    const result = await getApplicationCount();
    if (typeof result !== 'object')
      return catchError(result, navigate, setAlertBox, setAlert);
    setApplicationCount(result?.data?.info);
    setApplicationList(result?.data?.content);
    getSelfApplication();
  };

  //@ 2
  const getTodayUser = async () => {
    const result = await getUserCount('month');
    if (typeof result !== 'object')
      return catchError(result, navigate, setAlertBox, setAlert);
    setNewUser(result?.data?.data);
    getApplication();
  };

  //@ 1
  const getAllUser = async () => {
    if (prevent) return;
    prevent = true;
    setTimeout(() => {
      prevent = false;
    }, 200);
    const result = await getUserCount('all');
    if (typeof result !== 'object')
      return catchError(result, navigate, setAlertBox, setAlert);
    else {
      setState('ok');
      setAllUser(result?.data?.data);
      getTodayUser();
    }
  };

  //= 셀프 출원 리스트 렌더
  const renderSelfApplicationList = () => {
    return selfList?.map(
      ({ applicant_name, template_name, mark_name, mark_type, id }, idx) => {
        return (
          <tr onClick={() => navigate(`/self-application/${id}`)}>
            <td>{idx + 1}</td>
            <td>{template_name}</td>
            <td>{mark_name}</td>
            <td>{markTypeList[mark_type]}상표</td>
            <td>
              {applicant_name?.split(',')?.length === 1
                ? applicant_name
                : `${applicant_name?.split(',')[0]} 외 ${
                    applicant_name?.split(',')?.length - 1
                  }명`}
            </td>
          </tr>
        );
      },
      <></>
    );
  };

  //= 상표 출원 대행 리스트 렌더
  const renderApplicationList = () => {
    return applicationList?.map(
      ({ applicant_name, mark_name, mark_type, paid_amount, id }, idx) => {
        return (
          <tr onClick={() => navigate(`/agencies/${id}`)}>
            <td>{idx + 1}</td>
            <td>{mark_name}</td>
            <td>{markTypeList[mark_type]}상표</td>
            <td>{paid_amount?.toLocaleString()}원</td>
            <td>{applicant_name}</td>
          </tr>
        );
      },
      <></>
    );
  };

  //= 공지사항 5개 렌더
  const noticeListRender = () => {
    return recentNotice?.map(({ title, admin_name, created_at, id }) => {
      return (
        <tr
          onClick={() => {
            setNoticeId(id);
            setNoticeModal(true);
          }}>
          <td className='title'>{title}</td>
          <td>{admin_name}</td>
          <td>{created_at?.replaceAll('T', ' ')}</td>
        </tr>
      );
    }, <></>);
  };

  //= 문의사항 5개 렌더
  const inquiryListRender = () => {
    return recentInquiry?.map(({ title, user_name, created_at, id }) => {
      return (
        <tr
          onClick={() => {
            setInquiryId(id);
            setInquiryModal(true);
          }}>
          <td className='title'>{title}</td>
          <td>{user_name}</td>
          <td>{created_at?.replaceAll('T', ' ')}</td>
        </tr>
      );
    }, <></>);
  };

  useEffect(() => {
    if (state === '') {
      getAllUser();
      document.title = '마크픽 관리자 > 홈';
    }
    if (state === 'ok' && (!noticeModal || !editor)) getNotice();
    if (state === 'ok' && !inquiryModal) getInquiry();
  }, [noticeModal, editor, inquiryModal]);

  return (
    <div className='container'>
      <SideBar />
      <div className='content-wrap dashboard'>
        <h2>DASHBOARD</h2>
        <div className='row'>
          <div>
            <div className='title-wrap'>
              <h2>최근 업로드한 공지 사항</h2>
            </div>
            {!recentNotice?.length ? (
              <div className='none-list'>
                <MdOutlineMoodBad />
              </div>
            ) : (
              <div className='table-wrap'>
                <table>
                  <colgroup>
                    <col width='50%' />
                    <col width='20%' />
                    <col width='30%' />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>제목</th>
                      <th>작성자</th>
                      <th>공지 날짜</th>
                    </tr>
                  </thead>
                  <tbody>{noticeListRender()}</tbody>
                </table>
              </div>
            )}
          </div>
          <div>
            <div className='title-wrap'>
              <h2>최근 업로드 된 문의 사항</h2>
            </div>
            {!recentInquiry?.length ? (
              <div className='none-list'>
                <MdOutlineMoodBad />
              </div>
            ) : (
              <div className='table-wrap'>
                <table>
                  <colgroup>
                    <col width='50%' />
                    <col width='20%' />
                    <col width='30%' />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>제목</th>
                      <th>작성자</th>
                      <th>문의 날짜</th>
                    </tr>
                  </thead>
                  <tbody>{inquiryListRender()}</tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        <div className='row'>
          <div>
            <div className='title-wrap'>
              <h2>회원 현황</h2>
            </div>
            <div className='count-wrap column'>
              <div className='count'>
                {newUser} / {allUser}
              </div>
              <div>신규 / 전체</div>
            </div>
          </div>
          <div>
            <div className='title-wrap'>
              <h2>셀프 출원 현황</h2>
            </div>
            <div className='count-wrap column'>
              <div className='count'>
                {selfCount?.count} / {selfCount?.total}
              </div>
              <div>오늘 / 전체</div>
            </div>
          </div>
          <div>
            <div className='title-wrap'>
              <h2>출원 신청 현황</h2>
            </div>
            <div className='count-wrap column'>
              <div className='count'>
                {applicationCount?.today_count} /{' '}
                {applicationCount?.total_count}
              </div>
              <div>오늘 / 전체</div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div>
            <div className='title-wrap'>
              <h2>금일 셀프 출원 목록</h2>
              <p>최대 다섯 개까지 표시 됩니다.</p>
            </div>
            {!selfList?.length ? (
              <div className='none-list'>
                <MdOutlineMoodBad />
              </div>
            ) : (
              <div className='table-wrap application-list'>
                <table>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>템플릿명</th>
                      <th>출원 상표명</th>
                      <th>상표 유형</th>
                      <th>출원인</th>
                    </tr>
                  </thead>
                  <tbody>{renderSelfApplicationList()}</tbody>
                </table>
              </div>
            )}
          </div>
          <div>
            <div className='title-wrap'>
              <h2>금일 출원 신청 목록</h2>
              <p>최대 다섯 개까지 표시 됩니다.</p>
            </div>
            {!applicationList?.length ? (
              <div className='none-list'>
                <MdOutlineMoodBad />
              </div>
            ) : (
              <div className='table-wrap application-list'>
                <table>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>출원 상표명</th>
                      <th>상표 유형</th>
                      <th>결제 금액</th>
                      <th>출원인</th>
                    </tr>
                  </thead>
                  <tbody>{renderApplicationList()}</tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      {noticeModal && (
        <NoticeDetail
          noticeId={noticeId}
          setModal={setNoticeModal}
          setEditor={setEditor}
        />
      )}
      {inquiryModal && (
        <InquiryDetail inquiryId={inquiryId} setModal={setInquiryModal} />
      )}
      {editor && (
        <NoticeWrite
          noticeId={noticeId}
          setModal={setNoticeModal}
          setEditor={setEditor}
        />
      )}
      {alertBox.bool && (
        <CommonModal
          setModal={setAlertBox}
          modal={alertBox}
          okFn={() => {
            if (alert === 'logout') navigate('/');
            else return;
          }}
        />
      )}
    </div>
  );
};

export default Home;
