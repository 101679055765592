import { Link, useLocation, useNavigate } from 'react-router-dom';
import { removeCookie } from 'JS/cookie';
import logo from 'Image/markpickLogo.svg';

const SideBar = () => {
  const path = useLocation().pathname;
  const navigate = useNavigate();

  return (
    <>
      <div className='sideBar column'>
        <Link to='/home'>
          <img src={logo} alt='마크픽 로고' />
        </Link>
        <ul className='mainMenu'>
          <Link to='/home'>
            <li className={path === '/home' && 'active'}>DASHBOARD</li>
          </Link>
          <li className='main'>관리</li>
          <Link to='/manage'>
            <li className={path === '/manage' && 'active'}>회원 관리</li>
          </Link>
          {localStorage.getItem('admin_role') === 'super_admin' ? (
            <Link to='/admin-manage'>
              <li className={path === '/admin-manage' && 'active'}>
                관리자 계정 관리
              </li>
            </Link>
          ) : (
            ''
          )}
          <Link to='/inquiry'>
            <li className={path === '/inquiry' && 'active'}>문의 사항 관리</li>
          </Link>
          <Link to='/notice'>
            <li className={path === '/notice' && 'active'}>공지 사항 관리</li>
          </Link>
          <Link to='/popup'>
            <li className={path === '/popup' && 'active'}>팝업 관리</li>
          </Link>
          <li className='main'>출원 관리</li>
          <Link to='/self-application'>
            <li className={path.includes('/self-application') && 'active'}>
              셀프 출원 관리
            </li>
          </Link>
          <Link to='/agencies'>
            <li className={path.includes('/agencies') && 'active'}>
              출원 신청 관리
            </li>
          </Link>
          <li className='main'>이벤트</li>
          <Link to='/event-manage'>
            <li className={path === '/event-manage' && 'active'}>
              이벤트 관리
            </li>
          </Link>
          <li className='main'>바로가기</li>
          <a
            href='https://markpick.co.kr'
            target='_blank'
            rel='noopener noreferrer'>
            <li>마크픽</li>
          </a>
        </ul>
        <div
          className='logoutBtn'
          onClick={() => {
            removeCookie('pickAdminMyToken');
            removeCookie('pickAdminRfToken');
            localStorage.clear();
            return navigate('/');
          }}>
          로그아웃
        </div>
      </div>
    </>
  );
};

export default SideBar;
