import SideBar from 'Components/SideBar';

const EventManage = () => {
  return (
    <div className='container'>
      <SideBar />
      <div className='content-wrap'>
        <div className='topBar'>
          <h2>EVENT</h2>
        </div>
      </div>
    </div>
  );
};

export default EventManage;
