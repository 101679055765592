import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SideBar from 'Components/SideBar';
import Pagination from 'Components/Pagination';
import CommonModal from 'Components/CommonModal';
import { catchError, changeState } from 'JS/common';
import { statusList, simpleStatusList, applicantType } from 'JS/array';
import { getAgenciesList } from 'JS/API';
import { FaSortUp, FaSortDown } from 'react-icons/fa';

const Application = () => {
  const today = new Date();
  const [pageInfo, setPageInfo] = useState({
    page: 1,
    totalPage: 10,
    limit: 10,
  });
  const [searchData, setSearchData] = useState({
    markType: 'all',
    progress: 'all',
    applicantType: 'all',
    applicantName: '',
    startDate: '',
    endDate: '',
    markName: '',
    sort: 'apply_date_desc',
  });
  const [alert, setAlert] = useState('');
  const [alertBox, setAlertBox] = useState({
    mode: '',
    context: '',
    bool: false,
  });
  const [list, setList] = useState([]);
  let prevent = false;
  const navigate = useNavigate();

  const getList = async () => {
    if (prevent) return;
    prevent = true;
    setTimeout(() => (prevent = false), 200);
    const result = await getAgenciesList(pageInfo, searchData);
    if (typeof result === 'object') {
      setList(result?.data?.data);
      setPageInfo(prev => {
        const clone = { ...prev };
        clone.totalPage = result?.data?.meta?.total_page;
        return clone;
      });
    } else return await catchError(result, navigate, setAlertBox, setAlert);
  };

  const renderList = () => {
    return list?.map(
      (
        {
          id,
          mark_name,
          mark_type,
          name_kor_dom_corp,
          name_kor_dom_indiv,
          name_kor_dom_inst,
          name_kor_fgn_corp,
          name_kor_fgn_indiv,
          progress_status,
          created_at,
          paid_amount,
        },
        idx
      ) => {
        const returnApplicant = type => {
          let name_kor = '';
          let applicant_type = '';
          if (name_kor_dom_indiv) {
            name_kor = name_kor_dom_indiv;
            applicant_type = '국내개인';
          } else if (name_kor_dom_corp) {
            name_kor = name_kor_dom_corp;
            applicant_type = '국내법인';
          } else if (name_kor_fgn_indiv) {
            name_kor = name_kor_fgn_indiv;
            applicant_type = '외국개인';
          } else if (name_kor_fgn_corp) {
            name_kor = name_kor_fgn_corp;
            applicant_type = '외국법인';
          } else if (name_kor_dom_inst) {
            name_kor = name_kor_dom_inst;
            applicant_type = '국가기관';
          }

          if (type === 'name') return name_kor;
          else return applicant_type;
        };

        return (
          <tr onClick={() => navigate(`/agencies/${id}`)}>
            <td>{idx + 1}</td>
            <td>{mark_name?.trim()}</td>
            <td>
              {mark_type === 'TEXT'
                ? '문자'
                : mark_type === 'FIGURE'
                ? '도형'
                : '복합'}
              상표
            </td>
            <td>{paid_amount?.toLocaleString()}원</td>
            <td>{statusList[progress_status]}</td>
            <td>{returnApplicant('name')}</td>
            <td>{returnApplicant('applicantType')}</td>
            <td>{created_at?.replaceAll('T', ' ')}</td>
          </tr>
        );
      },
      <></>
    );
  };

  useEffect(() => {
    getList();
  }, [pageInfo.page, searchData.sort]);

  return (
    <>
      <div className='container'>
        <SideBar />
        <div className='content-wrap agencies'>
          <div className='topBar'>
            <h2>APPLICATION-AGENCIES</h2>
          </div>
          <div className='column search-wrap'>
            <div className='row'>
              <span>상표 유형</span>
              <select
                value={searchData.markType}
                onChange={e =>
                  changeState(setSearchData, 'markType', e.target.value)
                }>
                <option value='all'>선택</option>
                <option value='text'>문자상표</option>
                <option value='figure'>도형상표</option>
                <option value='compound'>복합상표</option>
              </select>
              <span>진행 상태</span>
              <select
                value={searchData.progress}
                onChange={e =>
                  changeState(setSearchData, 'progress', e.target.value)
                }>
                <option value='all'>선택</option>
                {Object.keys(simpleStatusList).map((key, idx) => {
                  return (
                    <option value={key}>
                      {Object.values(simpleStatusList)[idx]}
                    </option>
                  );
                }, <></>)}
              </select>
              <span>출원인 유형</span>
              <select
                value={searchData.applicantType}
                onChange={e =>
                  changeState(setSearchData, 'applicantType', e.target.value)
                }>
                <option value='all'>선택</option>
                {Object.keys(applicantType).map((key, idx) => {
                  return (
                    <option value={key}>
                      {Object.values(applicantType)[idx]}
                    </option>
                  );
                }, <></>)}
              </select>
            </div>
            <div className='row'>
              <span>출원인</span>
              <input
                type='text'
                value={searchData.applicantName}
                onChange={e =>
                  changeState(setSearchData, 'applicantName', e.target.value)
                }
              />
              <span>신청일</span>
              <input
                type='date'
                max={`${today.getFullYear()}-${
                  today.getMonth() + 1 < 10
                    ? `0${today.getMonth() + 1}`
                    : today.getMonth() + 1
                }-${
                  today.getDate() + 1 < 10
                    ? `0${today.getDate()}`
                    : today.getDate()
                }`}
                value={searchData.startDate}
                onChange={e =>
                  changeState(setSearchData, 'startDate', e.target.value)
                }
              />
              <span className='tilde'>~</span>
              <input
                type='date'
                max={`${today.getFullYear()}-${
                  today.getMonth() + 1 < 10
                    ? `0${today.getMonth() + 1}`
                    : today.getMonth() + 1
                }-${
                  today.getDate() + 1 < 10
                    ? `0${today.getDate()}`
                    : today.getDate()
                }`}
                value={searchData.endDate}
                onChange={e =>
                  changeState(setSearchData, 'endDate', e.target.value)
                }
              />
            </div>
            <div className='row mark-name'>
              <div className='row'>
                <span>출원 상표명</span>
                <input
                  type='text'
                  className='mark-name-input'
                  value={searchData.markName}
                  onChange={e =>
                    changeState(setSearchData, 'markName', e.target.value)
                  }
                />
              </div>
              <div className='btn-wrap row'>
                <button onClick={getList}>검색</button>
                <button
                  onClick={() => {
                    setSearchData(prev => {
                      const clone = { ...prev };
                      clone.markType = 'all';
                      clone.progress = 'all';
                      clone.applicantType = 'all';
                      clone.applicantName = '';
                      clone.startDate = '';
                      clone.endDate = '';
                      clone.markName = '';
                      return clone;
                    });
                    getList();
                  }}>
                  초기화
                </button>
              </div>
            </div>
          </div>
          <div className='table-wrap'>
            <table>
              <colgroup>
                <col width='5%' />
                <col width='20%' />
                <col width='10%' />
                <col width='15%' />
                <col width='10%' />
                <col width='10%' />
                <col width='10%' />
                <col width='20%' />
              </colgroup>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>출원 상표명</th>
                  <th>상표 유형</th>
                  <th>결제 금액</th>
                  <th>진행 상태</th>
                  <th>출원인</th>
                  <th>출원인 유형</th>
                  <th
                    className='sortBtn'
                    onClick={() => {
                      if (searchData.sort === 'apply_date_asc')
                        changeState(setSearchData, 'sort', 'apply_date_desc');
                      else changeState(setSearchData, 'sort', 'apply_date_asc');
                    }}>
                    신청일{' '}
                    {searchData.sort === 'apply_date_asc' ? (
                      <FaSortUp />
                    ) : (
                      <FaSortDown />
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>{renderList()}</tbody>
            </table>
          </div>
          <Pagination pageInfo={pageInfo} setPageInfo={setPageInfo} />
        </div>
      </div>
      {alertBox.bool && (
        <CommonModal
          setModal={setAlertBox}
          modal={alertBox}
          okFn={() => {
            if (alert === 'logout') navigate('/');
            else return;
          }}
        />
      )}
    </>
  );
};

export default Application;
