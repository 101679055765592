import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import SideBar from 'Components/SideBar';
import Pagination from 'Components/Pagination';
import CommonModal from 'Components/CommonModal';
import PersonnelModal from 'Components/PersonnelModal';
import {
  catchError,
  changeState,
  commonModalSetting,
  enterFn,
} from 'JS/common';
import { getUserList } from 'JS/API';

const Manage = () => {
  const [pageInfo, setPageInfo] = useState({
    page: 1,
    totalPage: 10,
    limit: 10,
  });
  const [searchTxt, setSearchTxt] = useState('');
  const [select, setSelect] = useState('all');
  const [alert, setAlert] = useState('');
  const [alertBox, setAlertBox] = useState({
    mode: '',
    context: '',
    bool: false,
  });
  const [infoModal, setInfoModal] = useState(false);
  const [user, setUser] = useState([]);
  const [id, setId] = useState('');

  let prevent = false;
  const navigate = useNavigate();

  //= 회원 목록 불러오기
  const userList = async () => {
    if (prevent) return;
    prevent = true;
    setTimeout(() => {
      prevent = false;
    }, 200);
    const result = await getUserList(pageInfo, select, searchTxt);
    if (typeof result === 'object') {
      setUser(result?.data?.data);
      changeState(setPageInfo, 'totalPage', result?.data?.meta?.total_page);
    } else return await catchError(result, navigate, setAlertBox, setAlert);
  };

  //= 회원 검색
  const userSearch = async () => {
    if (searchTxt.trim() !== '' && select === 'all')
      return commonModalSetting(
        setAlertBox,
        true,
        'alert',
        '검색하실 종류를 선택해 주세요.'
      );
    const result = await getUserList(pageInfo, select, searchTxt);
    if (typeof result === 'object') {
      setUser(result?.data?.data);
      changeState(setPageInfo, 'totalPage', result?.data?.meta?.total_page);
    } else return await catchError(result, navigate, setAlertBox, setAlert);
  };

  //= 회원 목록 테이블 렌더
  const renderUserList = () => {
    return user.map(
      ({ id, user_id, name, created_at, is_active, phone, email }) => {
        const onModal = () => {
          setId(id);
          setInfoModal(true);
        };

        return (
          <tr>
            <td onClick={onModal}>{user_id}</td>
            <td onClick={onModal}>{name}</td>
            <td onClick={onModal}>{email}</td>
            <td onClick={onModal}>
              {phone.slice(0, 3)}-{phone.slice(3, 7)}-{phone.slice(7)}
            </td>
            <td className={is_active ? 'user' : 'resign'} onClick={onModal}>
              {is_active ? '회원' : '탈퇴'}
            </td>
            <td onClick={onModal}>{created_at.split('T')[0]}</td>
          </tr>
        );
      },
      <></>
    );
  };

  useEffect(() => {
    document.title = '마크픽 관리자 > 회원 관리';
  }, []);

  useEffect(() => {
    userList();
  }, [pageInfo.page, pageInfo.limit]);

  return (
    <div className='container'>
      <SideBar />
      <div className='content-wrap manage'>
        <div className='topBar'>
          <h2>MEMBER</h2>
          <div>
            <select
              value={pageInfo.limit}
              onChange={e => {
                setPageInfo(prev => {
                  const clone = { ...prev };
                  clone.page = 1;
                  clone.limit = e.target.value;
                  return clone;
                });
              }}>
              <option value='10'>10명씩 보기</option>
              <option value='30'>30명씩 보기</option>
              <option value='50'>50명씩 보기</option>
            </select>
            <select value={select} onChange={e => setSelect(e.target.value)}>
              <option value='all'>전체 보기</option>
              <option value='uid'>아이디</option>
              <option value='name'>이름</option>
            </select>
            <input
              type='text'
              placeholder='검색어를 입력하세요.'
              value={searchTxt}
              onChange={e => setSearchTxt(e.target.value)}
              onKeyDown={e => {
                if (!alertBox.bool) enterFn(e, userSearch);
              }}
            />
            <button className='searchBtn' onClick={userSearch}>
              검색
              <span className='searchIcon'>
                <FaSearch />
              </span>
            </button>
          </div>
        </div>
        <div className='table-wrap'>
          <table>
            <thead>
              <tr>
                <th>아이디</th>
                <th>이름</th>
                <th>이메일</th>
                <th>전화번호</th>
                <th>회원구분</th>
                <th>가입일</th>
              </tr>
            </thead>
            <tbody>{renderUserList()}</tbody>
          </table>
        </div>
        <Pagination pageInfo={pageInfo} setPageInfo={setPageInfo} />
      </div>
      {alertBox.bool && (
        <CommonModal
          setModal={setAlertBox}
          modal={alertBox}
          okFn={() => {
            if (alert === 'logout') navigate('/');
            else return;
          }}
        />
      )}
      {infoModal && <PersonnelModal setInfoModal={setInfoModal} id={id} />}
    </div>
  );
};

export default Manage;
