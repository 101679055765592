import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { catchError, changeState, commonModalSetting } from 'JS/common';
import CommonModal from '../CommonModal';
import { applyProduct } from '../../JS/API';

const AddApplicationTypeModal = ({ id, setModal }) => {
  const [alert, setAlert] = useState('');
  const [alertBox, setAlertBox] = useState({
    mode: '',
    context: '',
    bool: false,
  });
  const [info, setInfo] = useState({
    mark_class: 'select',
    register_product: '',
    apply_type: 'NORMAL',
    apply_agent_pk: id,
  });
  const navigate = useNavigate();

  const applyProductFn = async () => {
    if (info.mark_class === 'select' || info.register_product.trim() === '') {
      setAlert('notValue')
      commonModalSetting(
        setAlertBox,
        true,
        'alert',
        '등록할 상품/업종을 정확히 입력해 주세요.'
      );
      return
    }
    const query = { ...info };
    query.register_product = query.register_product
      .replaceAll(', ', ',')
      .replaceAll(' ,', ',')
      .split(',');
    const result = await applyProduct(query);
    if (typeof result === 'object') {
      setAlert('completeApply');
      commonModalSetting(setAlertBox, true, 'alert', '추가되었습니다.');
    } else return catchError(result, navigate, setAlertBox, setAlert);
  };

  return (
    <>
      <div className='modal-background'>
        <div className='modal add-application-type'>
          <div className='topBar'>
            <h3>상품/업종 및 출원 유형 추가</h3>
          </div>
          <div className='column input-wrap'>
            <div className='row'>
              <span>상품 분류</span>
              <select
                value={info.mark_class}
                onChange={e =>
                  changeState(setInfo, 'mark_class', e.target.value)
                }>
                <option value='select'>선택</option>
                {new Array(45).fill(1).map((num, idx) => {
                  return (
                    <option value={`${num + idx}`}>
                      {num + idx < 10 ? `0${num + idx}` : num + idx}류
                    </option>
                  );
                }, <></>)}
              </select>
            </div>
            <div className='row'>
              <span>등록 상품</span>
              <input
                type='text'
                placeholder='등록 상품'
                value={info.register_product}
                onChange={e =>
                  changeState(setInfo, 'register_product', e.target.value)
                }
              />
            </div>
            <p className='caption'>
              쉼표(,)로 구분 지어 입력하시면 등록 상품을 여러 개 입력하실 수
              있습니다.
            </p>
            <div className='row'>
              <span>출원 유형</span>
              <select
                value={info.apply_type}
                onChange={e =>
                  changeState(setInfo, 'apply_type', e.target.value)
                }>
                <option value='NORMAL'>일반출원</option>
                <option value='SEARCH'>조사출원</option>
                <option value='FAST'>신속출원</option>
                <option value='FASTSEARCH'>신속조사출원</option>
              </select>
            </div>
          </div>
          <div className='btnWrap'>
            <button onClick={applyProductFn}>추가</button>
            <button
              onClick={() => {
                setAlert('cancel');
                commonModalSetting(
                  setAlertBox,
                  true,
                  'confirm',
                  '정말 취소하시겠습니까?<br/>지금까지 작성된 내용은 반영되지 않습니다.'
                );
              }}>
              취소
            </button>
          </div>
        </div>
      </div>
      {alertBox.bool && (
        <CommonModal
          setModal={setAlertBox}
          modal={alertBox}
          okFn={() => {
            if (alert === 'cancel' || alert === 'completeApply')
              changeState(setModal, 'applicationType', false);
            else if (alert === 'logout') navigate('/');
            else return;
          }}
        />
      )}
    </>
  );
};

export default AddApplicationTypeModal;
