import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SideBar from 'Components/SideBar';
import CommonModal from 'Components/CommonModal';
import MarkNameEditModal from 'Components/Agencies/MarkNameEditModal';
import AddAmountModal from 'Components/Agencies/AddAmountModal';
import AddApplicationTypeModal from 'Components/Agencies/AddApplicationTypeModal';
import {
  applyApplicationNumber,
  deleteProduct,
  editMarkType,
  editProgress,
  getAgenciesDetail,
  getApplicationInfo,
  getPaymentTemp,
} from 'JS/API';
import { catchError, changeState, commonModalSetting } from 'JS/common';
import { markTypeList, paidStatusList, statusList } from 'JS/array';

const ApplicationDetail = () => {
  const [info, setInfo] = useState({});
  const [products, setProducts] = useState([]);
  const [applicationInfo, setApplicationInfo] = useState({});
  const [alert, setAlert] = useState('');
  const [progress, setProgress] = useState('');
  const [final, setFinal] = useState({
    final_price: 0,
    final_type: '',
  });
  const [paymentTemp, setPaymentTemp] = useState([]);
  const [modal, setModal] = useState({
    addAmount: false,
    applicationType: false,
  });
  const [edit, setEdit] = useState({
    progress: false,
    markName: false,
    markType: false,
    applicationNumber: false,
  });
  const [alertBox, setAlertBox] = useState({
    mode: '',
    context: '',
    bool: false,
  });
  const [applicationNumber, setApplicationNumber] = useState({
    first: '',
    middle: '',
    last: '',
  });

  const { id } = useParams();
  const navigate = useNavigate();
  let prevent = false;

  const getPaymentTempList = async () => {
    const result = await getPaymentTemp(id);
    if (typeof result === 'object') {
      const { final_price, final_type, info } = result?.data;
      setFinal({
        final_price: final_price,
        final_type: final_type,
      });
      setPaymentTemp(info);
    } else return await catchError(result, navigate, setAlertBox, setAlert);
  };

  const getInfo = async () => {
    const result = await getApplicationInfo(id);
    if (typeof result === 'object') {
      setApplicationInfo(result?.data);
      getPaymentTempList();
      if (!result?.data) changeState(setEdit, 'applicationNumber', true);
    } else return await catchError(result, navigate, setAlertBox, setAlert);
  };

  const getDetail = async () => {
    if (prevent) return;
    prevent = true;
    setTimeout(() => (prevent = false), 200);
    const result = await getAgenciesDetail(id);
    if (typeof result === 'object') {
      setInfo(result?.data);
      setProducts(result?.data?.products);
      getInfo();
    } else return await catchError(result, navigate, setAlertBox, setAlert);
  };

  const deleteProductFn = async mark_class => {
    const query = { apply_agent_pk: id, mark_class: mark_class };
    const result = await deleteProduct(query);
    if (typeof result === 'object') {
      getDetail();
      setAlert('completeDelete');
      commonModalSetting(setAlertBox, true, 'alert', '삭제되었습니다.');
    } else return await catchError(result, navigate, setAlertBox, setAlert);
  };

  const progressEditFn = async () => {
    changeState(setEdit, 'progress', true);
    const result = await editProgress(id, progress);
    if (typeof result === 'object') {
      setAlert('completeEdit');
      commonModalSetting(setAlertBox, true, 'alert', '처리 되었습니다.');
      changeState(setEdit, 'progress', false);
    } else return await catchError(result, navigate, setAlertBox, setAlert);
  };

  const markTypeEditFn = async () => {
    const result = await editMarkType(id, info?.mark_type);
    if (typeof result === 'object') {
      changeState(setEdit, 'markType', false);
    } else return await catchError(result, navigate, setAlertBox, setAlert);
  };

  const applyNumber = async () => {
    if (
      !applicationNumber.first ||
      !applicationNumber.middle ||
      !applicationNumber.last
    )
      return commonModalSetting(
        setAlertBox,
        true,
        'alert',
        '출원 번호를 입력해 주세요.'
      );
    const result = await applyApplicationNumber(id, applicationNumber);
    if (typeof result === 'object') {
      await getInfo();
    }
  };

  useEffect(() => {
    if (!edit.markName || !edit.markType || !edit.progress) getDetail();
  }, [edit.markName, edit.markType, edit.progress]);

  useEffect(() => {
    setProgress(info?.progress_status);
  }, [info?.progress_status]);

  useEffect(() => {
    setApplicationNumber({
      first: applicationInfo?.applicationNumber?.split('-')[0],
      middle: applicationInfo?.applicationNumber?.split('-')[1],
      last: applicationInfo?.applicationNumber?.split('-')[2],
    });
  }, [applicationInfo]);

  return (
    <>
      {' '}
      <div className='container'>
        <SideBar />
        <div className='content-wrap agencies-detail'>
          <div className='topBar'>
            <h2>APPLICATION-DETAIL</h2>
          </div>
          <div className='detail-page'>
            <h5>진행 및 결제 상태</h5>
            <div className='table-wrap payment-status'>
              <table>
                <colgroup>
                  <col width='15%' />
                  <col width='35%' />
                  <col width='15%' />
                  <col width='35%' />
                </colgroup>
                <thead>
                  <tr>
                    <th className='title'>진행 상태</th>
                    <th>{statusList[info?.progress_status]}</th>
                    <th className='title'>처리 선택</th>
                    <th>
                      <div className='row'>
                        <select
                          value={progress}
                          onChange={e => setProgress(e.target.value)}>
                          {Object.keys(statusList).map((key, idx) => {
                            return (
                              <option
                                value={key}
                                className={key === 'RegAbandon' ? 'red' : ''}>
                                {Object.values(statusList)[idx]}
                              </option>
                            );
                          }, <></>)}
                        </select>
                        <button onClick={progressEditFn}>처리</button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='title'>결제 상태</td>
                    <td>{paidStatusList[info?.paid_status]}</td>
                    <td className='title'>결제 금액</td>
                    <td>{info?.paid_amount?.toLocaleString() || 0}원</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='table-wrap payment-temp-list'>
              <table>
                <colgroup>
                  <col width='15%' />
                  <col width='70%' />
                  <col width='15%' />
                </colgroup>
                <tbody>
                  {paymentTemp.length ? (
                    <>
                      <tr>
                        <td className='title' rowSpan={paymentTemp?.length}>
                          결제 변동 내역
                        </td>
                        <td>
                          {paymentTemp[0]?.type === 'pay'
                            ? '추가 결제'
                            : '부분 환불'}{' '}
                          | {paymentTemp[0]?.amount.toLocaleString()}원 |{' '}
                          {paymentTemp[0]?.reason}
                          {paymentTemp[0]?.type === 'pay' &&
                            ` (${paymentTemp[0]?.mark_class}류)`}{' '}
                          | {paymentTemp[0]?.created_at?.replaceAll('T', ' ')}
                        </td>
                        <td
                          rowSpan={paymentTemp?.length}
                          className='payment-btn'>
                          <div className='column'>
                            {final?.final_price.toLocaleString()}원
                            <button>
                              {final?.final_type === 'pay'
                                ? '추가 결제'
                                : '부분 환불'}{' '}
                              요청
                            </button>
                          </div>
                        </td>
                      </tr>
                      {paymentTemp?.length > 1
                        ? paymentTemp
                            .slice(1)
                            ?.map(
                              ({
                                type,
                                amount,
                                created_at,
                                reason,
                                mark_class,
                              }) => {
                                return (
                                  <tr>
                                    <td>
                                      {type === 'pay'
                                        ? '추가 결제'
                                        : '부분 환불'}{' '}
                                      | {amount?.toLocaleString()}원 | {reason}{' '}
                                      {type === 'pay' && `(${mark_class}류)`} |{' '}
                                      {created_at?.replaceAll('T', ' ')}
                                    </td>
                                  </tr>
                                );
                              },
                              <></>
                            )
                        : ''}
                    </>
                  ) : (
                    ''
                  )}
                </tbody>
              </table>
              <button onClick={() => changeState(setModal, 'addAmount', true)}>
                수동 결제 추가
              </button>
            </div>
            <h5>출원 신청 내역</h5>
            <div className='table-wrap application-status'>
              <table>
                <colgroup>
                  <col width='25%' />
                  <col width='25%' />
                  <col width='20%' />
                  <col width='30%' />
                </colgroup>
                <thead>
                  <tr>
                    <th className='title'>출원 상표명</th>
                    <th>
                      <div className='row'>
                        <span>
                          {info?.mark_name?.split('|')?.map((item, idx) => {
                            return (
                              <>
                                {item}
                                {idx === info?.mark_name?.split('|') ? '' : ' '}
                              </>
                            );
                          }, <></>)}
                          {/* {info?.mark_name_step1}
                          {info?.mark_name_step2
                            ? ` ${info?.mark_name_step2}`
                            : ''}
                          {info?.mark_name_step3
                            ? ` ${info?.mark_name_step3}`
                            : ''}
                          {info?.mark_name_step4
                            ? ` ${info?.mark_name_step4}`
                            : ''}
                          {info?.mark_name_step5
                            ? ` ${info?.mark_name_step5}`
                            : ''} */}
                        </span>
                        <button
                          onClick={() =>
                            changeState(setEdit, 'markName', true)
                          }>
                          {edit.markName ? '완료' : '수정'}
                        </button>
                      </div>
                    </th>
                    <th className='title'>출원 의뢰일자</th>
                    <th>{info?.created_at?.replaceAll('T', ' ')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='title'>상표 유형</td>
                    <td colSpan={3}>
                      <div className='row'>
                        <span>
                          {edit?.markType ? (
                            <>
                              <select
                                value={info?.mark_type}
                                onChange={e =>
                                  changeState(info, 'mark_type', e.target.value)
                                }>
                                <option value='FIGURE'>도형상표</option>
                                <option value='COMPOUND'>복합상표</option>
                              </select>
                            </>
                          ) : (
                            <>{markTypeList[info?.mark_type]}상표</>
                          )}
                        </span>
                        {info?.mark_type === 'TEXT' ? (
                          ''
                        ) : (
                          <button
                            onClick={() => {
                              if (edit?.markType) markTypeEditFn();
                              else changeState(setEdit, 'markType', true);
                            }}>
                            {edit?.markType ? '완료' : '수정'}
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      rowSpan={products?.length ? products?.length : 1}
                      className='title'>
                      <div className='row'>
                        <span>상품/업종 및 출원 유형</span>
                        <button
                          onClick={() =>
                            changeState(setModal, 'applicationType', true)
                          }>
                          추가
                        </button>
                      </div>
                    </td>
                    {
                      <td colSpan={3}>
                        <div className='row'>
                          {products[0] ? (
                            <>
                              <span>
                                {products[0]?.mark_class}류 /{' '}
                                {products[0]?.register_product} /{' '}
                                {products[0]?.apply_type === 'NORMAL'
                                  ? '일반'
                                  : products[0]?.apply_type === 'SEARCH'
                                  ? '조사'
                                  : products[0]?.apply_type === 'FAST'
                                  ? '신속'
                                  : '신속조사'}
                                출원
                              </span>
                              <button
                                onClick={() => {
                                  setAlert(
                                    `confirmDelete/${products[0]?.mark_class}`
                                  );
                                  commonModalSetting(
                                    setAlertBox,
                                    true,
                                    'confirm',
                                    '정말 삭제하시겠습니까?'
                                  );
                                }}>
                                삭제
                              </button>
                            </>
                          ) : (
                            <>없습니다</>
                          )}
                        </div>
                      </td>
                    }
                  </tr>
                  {products?.length > 1
                    ? products
                        ?.slice(1)
                        ?.map(
                          (
                            { mark_class, register_product, apply_type },
                            idx
                          ) => {
                            return (
                              <tr>
                                <td colSpan={3}>
                                  <div className='row'>
                                    <span>
                                      {mark_class}류 / {register_product} /{' '}
                                      {apply_type === 'NORMAL'
                                        ? '일반'
                                        : apply_type === 'SEARCH'
                                        ? '조사'
                                        : apply_type === 'FAST'
                                        ? '신속'
                                        : '신속조사'}
                                      출원
                                    </span>
                                    <button
                                      onClick={() => {
                                        setAlert(`confirmDelete/${mark_class}`);
                                        commonModalSetting(
                                          setAlertBox,
                                          true,
                                          'confirm',
                                          '정말 삭제하시겠습니까?'
                                        );
                                      }}>
                                      삭제
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          },
                          <></>
                        )
                    : ''}
                  <tr>
                    <td className='title'>등록 가능성 조사 여부</td>
                    <td>{info?.search ? 'O' : 'X'}</td>
                    <td className='title'>우선 심사 여부</td>
                    <td>{info?.fast ? 'O' : 'X'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h5>출원인 정보</h5>
            <div className='table-wrap applicant-info'>
              <table>
                <colgroup>
                  <col width='15%' />
                  <col width='35%' />
                  <col width='15%' />
                  <col width='35%' />
                </colgroup>
                <thead>
                  <tr>
                    <th className='title'>주민등록번호</th>
                    <th>
                      {info?.social_security_number ||
                        info?.foreigner_register_number}
                    </th>
                    <th className='title'>이름</th>
                    <th>
                      <div className='column'>
                        <span>(국문) {info?.name_kor}</span>
                        <span>(영문) {info?.name_eng}</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='title'>주소</td>
                    <td>
                      {info?.address} {info?.detail_address} ({info?.zip_code})
                    </td>
                    <td className='title'>이메일</td>
                    <td>{info?.email === '@' ? '' : info?.email}</td>
                  </tr>
                  <tr>
                    <td className='title'>휴대전화</td>
                    <td>
                      {info?.phone_number === '--' ? '' : info?.phone_number}
                    </td>
                    <td className='title'>유선전화</td>
                    <td>{info?.tel_number === '--' ? '' : info?.tel_number}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h5>출원 정보 등록</h5>
            <div className='row add-info'>
              <div className='application-number row'>
                <span>출원 번호: </span>
                <div className='input-wrap'>
                  {edit?.applicationNumber ? (
                    <>
                      <input
                        type='text'
                        className='first'
                        maxLength={2}
                        value={applicationNumber.first}
                        onChange={e =>
                          changeState(
                            setApplicationNumber,
                            'first',
                            e.target.value
                              .replaceAll(/[^0-9.]/g, '')
                              .replaceAll(/(\..*)\./g, '$1')
                          )
                        }
                      />
                      <span className='hyphen'>-</span>
                      <input
                        type='text'
                        className='middle'
                        maxLength={4}
                        value={applicationNumber.middle}
                        onChange={e =>
                          changeState(
                            setApplicationNumber,
                            'middle',
                            e.target.value
                              .replaceAll(/[^0-9.]/g, '')
                              .replaceAll(/(\..*)\./g, '$1')
                          )
                        }
                      />
                      <span className='hyphen'>-</span>
                      <input
                        type='text'
                        className='last'
                        maxLength={7}
                        value={applicationNumber.last}
                        onChange={e =>
                          changeState(
                            setApplicationNumber,
                            'last',
                            e.target.value
                              .replaceAll(/[^0-9.]/g, '')
                              .replaceAll(/(\..*)\./g, '$1')
                          )
                        }
                      />
                    </>
                  ) : (
                    <span>{applicationInfo?.applicationNumber}</span>
                  )}
                </div>
              </div>
              <button
                onClick={() => {
                  if (edit?.applicationNumber) {
                    changeState(setEdit, 'applicationNumber', false);
                    applyNumber();
                  } else {
                    changeState(setEdit, 'applicationNumber', true);
                  }
                }}>
                {edit?.applicationNumber ? '등록' : '수정'}
              </button>
            </div>
            {applicationInfo ? (
              <div className='table-wrap application-info'>
                <table>
                  <colgroup>
                    <col width='15%' />
                    <col width='35%' />
                    <col width='15%' />
                    <col width='35%' />
                  </colgroup>
                  <thead>
                    <tr>
                      <th className='title'>출원 번호</th>
                      <th>{applicationInfo?.applicationNumber}</th>
                      <th className='title'>출원일자</th>
                      <th>{applicationInfo?.applicationDate?.split('T')[0]}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='title'>심사 상태</td>
                      <td>{applicationInfo?.status}</td>
                      <td className='title'>명칭</td>
                      <td>{applicationInfo?.title}</td>
                    </tr>
                    <tr>
                      <td className='title'>출원인</td>
                      <td>{applicationInfo?.applicantName}</td>
                      <td className='title'>대리인</td>
                      <td>{applicationInfo?.agentName}</td>
                    </tr>
                    <tr>
                      <td className='title'>등록 번호</td>
                      <td>{applicationInfo?.registrationNumber}</td>
                      <td className='title'>등록일자</td>
                      <td>
                        {applicationInfo?.registrationDate?.split('T')[0]}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      {alertBox.bool && (
        <CommonModal
          setModal={setAlertBox}
          modal={alertBox}
          okFn={() => {
            if (alert === 'logout') navigate('/');
            else if (alert.includes('confirmDelete'))
              deleteProductFn(alert.split('/')[1]);
            else return;
          }}
        />
      )}
      {edit.markName && (
        <MarkNameEditModal
          setEdit={setEdit}
          id={id}
          defaultMarkNameArr={info?.mark_name?.split('|')}
        />
      )}
      {modal.addAmount && <AddAmountModal setModal={setModal} />}
      {modal.applicationType && (
        <AddApplicationTypeModal id={id} setModal={setModal} />
      )}
    </>
  );
};

export default ApplicationDetail;
