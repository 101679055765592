import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiFillPlusSquare, AiFillMinusSquare } from 'react-icons/ai';
import { catchError, changeState, commonModalSetting } from 'JS/common';
import CommonModal from '../CommonModal';
import { editMarkName } from 'JS/API';

const MarkNameEditModal = ({ setEdit, id, defaultMarkNameArr }) => {
  const [markNameArr, setMarkNameArr] = useState([...defaultMarkNameArr]);
  const [alert, setAlert] = useState('');
  const [alertBox, setAlertBox] = useState({
    mode: '',
    context: '',
    bool: false,
  });

  const navigate = useNavigate();

  const editFn = async () => {
    const data = {
      mark_name_step1: markNameArr[0] || '',
      mark_name_step2: markNameArr[1] || '',
      mark_name_step3: markNameArr[2] || '',
      mark_name_step4: markNameArr[3] || '',
      mark_name_step5: markNameArr[4] || '',
    };
    const result = await editMarkName(id, data);
    if (typeof result === 'object') {
      setAlert('completeEdit');
      commonModalSetting(setAlertBox, true, 'alert', '수정이 완료되었습니다.');
    } else return await catchError(result, navigate, setAlertBox, setAlert);
  };

  return (
    <>
      <div className='modal-background'>
        <div className='modal mark-name-edit'>
          <div className='topBar'>
            <h3>상표명 수정</h3>
          </div>
          <div className='column mark-name'>
            {markNameArr.map((markName, idx) => {
              return (
                <div className='row'>
                  <span>{idx + 1}단</span>
                  <input
                    type='text'
                    value={markName}
                    onChange={e =>
                      setMarkNameArr(prev => {
                        const clone = [...prev];
                        clone[idx] = e.target.value;
                        return clone;
                      })
                    }
                  />
                  {idx === 0 ? (
                    <AiFillPlusSquare
                      className={markNameArr?.length >= 5 ? 'display-none' : ''}
                      onClick={() =>
                        setMarkNameArr(prev => {
                          const clone = [...prev];
                          clone.push('');
                          return clone;
                        })
                      }
                    />
                  ) : (
                    <AiFillMinusSquare
                      onClick={() =>
                        setMarkNameArr(prev => {
                          const clone = [...prev];
                          clone.splice(idx, 1);
                          return clone;
                        })
                      }
                    />
                  )}
                </div>
              );
            }, <></>)}
          </div>
          <div className='btnWrap row'>
            <button onClick={editFn}>수정</button>
            <button
              onClick={() => {
                setAlert('cancel');
                commonModalSetting(
                  setAlertBox,
                  true,
                  'confirm',
                  '정말 취소하시겠습니까?<br/>지금까지 수정된 내용은 반영되지 않습니다.'
                );
              }}>
              취소
            </button>
          </div>
        </div>
      </div>
      {alertBox.bool && (
        <CommonModal
          setModal={setAlertBox}
          modal={alertBox}
          okFn={() => {
            if (alert === 'cancel' || alert === 'completeEdit')
              changeState(setEdit, 'markName', false);
            else if (alert === 'logout') navigate('/');
            else return;
          }}
        />
      )}
    </>
  );
};

export default MarkNameEditModal;
