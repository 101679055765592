import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaWindowClose } from 'react-icons/fa';
import { TbDragDrop } from 'react-icons/tb';
import CommonModal from './CommonModal';
import {
  outClick,
  commonModalSetting,
  changeState,
  addZero,
  catchError,
  getKeyByValue,
} from 'JS/common';
import { uploadPopupImage, createPopup, editPopup, deletePopup } from 'JS/API';

const PopupApplyModal = ({ setModal, mode, info, setInfo }) => {
  const [alert, setAlert] = useState('');
  const [alertBox, setAlertBox] = useState({
    mode: '',
    context: '',
    bool: false,
  });
  const [upload, setUpload] = useState(false);
  const [uploadImg, setUploadImg] = useState('');
  const [dragState, setDragState] = useState('leave');
  const date = new Date();
  const [activeDate, setActiveDate] = useState({
    start_date: `${date.getFullYear()}-${addZero(
      date.getMonth() + 1
    )}-${addZero(date.getDate())}`,
    start_time: '12:00',
    end_date: `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(
      date.getDate()
    )}`,
    end_time: '12:00',
  });
  const navigate = useNavigate();

  //= 팝업 등록
  const newPopup = async img_url => {
    const data = { ...info };
    data.img_url = img_url;
    delete data.id;
    const result = await createPopup(data);
    if (typeof result === 'object') {
      setAlert('completeApply');
      commonModalSetting(setAlertBox, 'alert', true, '등록이 완료되었습니다.');
    } else return await catchError(result, navigate, setAlertBox, setAlert);
  };

  //= 팝업 수정
  const modifyPopup = async img_url => {
    const data = { ...info };
    if (!info?.img_url && info?.img) data.img_url = img_url;
    delete data.id;
    const result = await editPopup(info?.id, data);
    if (typeof result === 'object') {
      setAlert('completeEdit');
      commonModalSetting(setAlertBox, 'alert', true, '수정이 완료되었습니다.');
    } else return await catchError(result, navigate, setAlertBox, setAlert);
  };

  //= 이미지 업로드
  const uploadImage = async () => {
    if (info?.img_url && !info?.img) return modifyPopup();
    const formData = new FormData();
    formData.append('popup', info?.img);
    const result = await uploadPopupImage(formData);
    if (typeof result === 'object') {
      delete info?.img;
      if (mode === 'edit') modifyPopup(result?.data[0]?.url);
      else newPopup(result?.data[0]?.url);
    } else return await catchError(result, navigate, setAlertBox, setAlert);
  };

  //= 팝업 삭제
  const removePopup = async () => {
    const result = await deletePopup([info?.id]);
    if (typeof result === 'object') {
      setAlert('completeDelete');
      commonModalSetting(setAlertBox, 'alert', true, '삭제가 완료되었습니다.');
    } else return await catchError(result, navigate, setAlertBox, setAlert);
  };

  useEffect(() => {
    window.addEventListener('click', e => outClick(e, setModal));
  }, []);

  //= 이미지 미리보기
  const imgPreview = inputFile => {
    if (!inputFile) return; //파일 없을때 처리
    const reader = new FileReader();
    reader.onload = () => {
      setUploadImg(reader.result);
    };
    reader.readAsDataURL(inputFile[0]);
  };

  useEffect(() => {
    setInfo(prev => {
      const clone = { ...prev };
      clone.start_date = Math.floor(
        new Date(
          `${activeDate.start_date} ${activeDate.start_time}`
        ).getTime() / 1000
      );
      clone.end_date = Math.floor(
        new Date(`${activeDate.end_date} ${activeDate.end_time}`).getTime() /
          1000
      );
      return clone;
    });
  }, [activeDate]);

  useEffect(() => {
    if (mode === 'edit') {
      const start = new Date(info?.start_date * 1000);
      const end = new Date(info?.end_date * 1000);
      setActiveDate({
        start_date: `${start.getFullYear()}-${addZero(
          start?.getMonth() + 1
        )}-${addZero(start?.getDate())}`,
        start_time: `${addZero(start.getHours())}:${addZero(
          start.getMinutes()
        )}`,
        end_date: `${end.getFullYear()}-${addZero(
          end?.getMonth() + 1
        )}-${addZero(end?.getDate())}`,
        end_time: `${addZero(end.getHours())}:${addZero(end.getMinutes())}`,
      });
      setUpload(true);
      setUploadImg(info?.img_url);
    }
  }, []);

  return (
    <>
      <div className='modal-background'>
        <div className='modal applyPopup'>
          <div className='topBar'>
            <h2>{mode === 'apply' ? '팝업 등록' : '팝업 수정'}</h2>
            <div onClick={() => setModal(false)}>
              <FaWindowClose />
            </div>
          </div>
          <div className='column'>
            <input
              type='file'
              onChange={e => {
                imgPreview(e.target.files);
                changeState(setInfo, 'img', e.target.files[0]);
                setUpload(true);
              }}
              accept='image/*'
              id='fileInput'
            />
            {upload ? (
              <label className='imageInput' htmlFor='fileInput'>
                <img src={uploadImg} alt='업로드된 이미지' />
              </label>
            ) : (
              <label
                htmlFor='fileInput'
                className={`imageInput column ${dragState}`}
                onDragOver={e => e.preventDefault()}
                onDrop={e => {
                  e.preventDefault();
                  const files = e.dataTransfer?.files;
                  if (files.length > 1)
                    return commonModalSetting(
                      setAlertBox,
                      true,
                      'alert',
                      '한 장만 업로드하실 수 있습니다.'
                    );
                  changeState(setInfo, 'img', files[0]);
                  imgPreview(files);
                  setDragState('leave');
                  setUpload(true);
                }}
                onDragEnter={() => setDragState('enter')}
                onDragLeave={() => setDragState('leave')}>
                <TbDragDrop />
                <span>이미지를 업로드해 주세요.</span>
              </label>
            )}
            <div className='row'>
              <span>링크</span>
              <input
                type='text'
                placeholder='이미지와 함께 첨부할 URL을 입력해 주세요.'
                value={info?.link_url}
                onChange={e => changeState(setInfo, 'link_url', e.target.value)}
              />
            </div>
            <div className='row'>
              <span>팝업 게시일</span>
              <div className='dateInput row'>
                <input
                  type='date'
                  max='9999-12-31'
                  value={activeDate.start_date}
                  onChange={e =>
                    changeState(setActiveDate, 'start_date', e.target.value)
                  }
                />
                <input
                  type='time'
                  max='9999-12-31'
                  value={activeDate.start_time}
                  onChange={e =>
                    changeState(setActiveDate, 'start_time', e.target.value)
                  }
                />
              </div>
            </div>
            <div className='row'>
              <span>팝업 만료일</span>
              <div className='dateInput row'>
                <input
                  type='date'
                  value={activeDate.end_date}
                  onChange={e =>
                    changeState(setActiveDate, 'end_date', e.target.value)
                  }
                />
                <input
                  type='time'
                  value={activeDate.end_time}
                  onChange={e =>
                    changeState(setActiveDate, 'end_time', e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className='btnWrap row'>
            <button onClick={uploadImage}>
              {mode === 'edit' ? '수정' : '등록'}
            </button>
            {mode === 'edit' ? (
              <button
                onClick={() => {
                  setAlert('confirmDelete');
                  commonModalSetting(
                    setAlertBox,
                    true,
                    'confirm',
                    '팝업을 삭제하시겠습니까?<br/>삭제된 팝업은 복구할 수 없습니다.'
                  );
                }}>
                삭제
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      {alertBox.bool && (
        <CommonModal
          setModal={setAlertBox}
          modal={alertBox}
          okFn={() => {
            if (
              alert === 'completeApply' ||
              alert === 'completeEdit' ||
              alert === 'completeDelete'
            )
              setModal(false);
            else if (alert === 'confirmDelete') removePopup();
            else if (alert === 'logout') navigate('/');
            else return;
          }}
        />
      )}
    </>
  );
};

export default PopupApplyModal;
