import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaWindowClose } from 'react-icons/fa';
import CommonModal from 'Components/CommonModal';
import { outClick, catchError } from 'JS/common';
import { getUserInfo } from 'JS/API';

const PersonnelModal = ({ setInfoModal, id }) => {
  const [info, setInfo] = useState({});
  const [alert, setAlert] = useState('');
  const [alertBox, setAlertBox] = useState({
    mode: '',
    context: '',
    bool: false,
  });
  let prevent = false;
  const navigate = useNavigate();

  const getUserDetail = async () => {
    if (prevent) return;
    prevent = true;
    setTimeout(() => {
      prevent = false;
    }, 200);
    const result = await getUserInfo(id);
    if (typeof result === 'object') setInfo(result?.data?.data);
    else return await catchError(result, navigate, setAlertBox, setAlert);
  };

  useEffect(() => {
    window.addEventListener('click', e => outClick(e, setInfoModal));
    getUserDetail();
  }, []);

  return (
    <>
      <div className='modal-background'>
        <div className='modal personnel'>
          <div className='topBar'>
            <h3>회원 상세보기</h3>
            <div onClick={() => setInfoModal(false)}>
              <FaWindowClose />
            </div>
          </div>
          <div className='personnelInput column'>
            <div className='row'>
              <div className='row user-id'>
                <span>아이디</span>
                <div className='infoInput'>{info?.user_id}</div>
              </div>
              <div className='blank'></div>
              <div className='row name'>
                <span>성명</span>
                <div className='infoInput'>{info?.name}</div>
              </div>
            </div>
            <div className='row'>
              <span>전화번호</span>
              <div className='infoInput'>
                {info?.phone?.slice(0, 3)}-{info?.phone?.slice(3, 7)}-
                {info?.phone?.slice(7)}
              </div>
            </div>
            <div className='row'>
              <span>이메일</span>
              <div className='infoInput'>{info?.email}</div>
            </div>
          </div>
        </div>
      </div>
      {alertBox.bool && (
        <CommonModal
          setModal={setAlertBox}
          modal={alertBox}
          okFn={() => {
            if (alert === 'logout') navigate('/');
            else return;
          }}
        />
      )}
    </>
  );
};

export default PersonnelModal;
