import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SideBar from 'Components/SideBar';
import CommonModal from 'Components/CommonModal';
import { getSelfDetail } from 'JS/API';
import { markTypeList } from 'JS/array';
import { catchError } from 'JS/common';

const SelfApplicationDetail = () => {
  const [info, setInfo] = useState({});
  const [applicantInfo, setApplicantInfo] = useState([]);
  const [alert, setAlert] = useState('');
  const [alertBox, setAlertBox] = useState({
    mode: '',
    context: '',
    bool: false,
  });

  const { id } = useParams();
  const navigate = useNavigate();
  let prevent = false;

  const getSelfApplicationInfo = async () => {
    if (prevent) return;
    prevent = true;
    setTimeout(() => (prevent = false), 200);
    const result = await getSelfDetail(id);
    if (typeof result === 'object') {
      setInfo(result?.data);
      const arr = [];
      for (let i = 0; i < 5; i++) {
        if (result?.data[`applicant_code${i + 1}`] !== '')
          arr.push({
            code: result?.data[`applicant_code${i + 1}`],
            name: result?.data[`applicant_name${i + 1}`],
            share: result?.data[`applicant_share${i + 1}`],
          });
      }
      setApplicantInfo(arr);
    } else return await catchError(result, navigate, setAlertBox, setAlert);
  };

  const renderApplicantInfo = () => {
    return applicantInfo?.map(({ code, name, share }, idx) => {
      return (
        <tr>
          <td>{idx + 1}</td>
          <td>{name}</td>
          <td>{code}</td>
          <td>{share} / 100</td>
        </tr>
      );
    }, <></>);
  };

  useEffect(() => {
    getSelfApplicationInfo();
  }, []);

  return (
    <>
      <div className='container'>
        <SideBar />
        <div className='content-wrap agencies-detail'>
          <div className='topBar'>
            <h2>SELF-APPLICATION-DETAIL</h2>
          </div>
          <div className='detail-page'>
            <h5>셀프 출원 내역</h5>
            <div className='table-wrap self-application-list'>
              <table>
                <colgroup>
                  <col width='15%' />
                  <col width='35%' />
                  <col width='15%' />
                  <col width='35%' />
                </colgroup>
                <thead>
                  <tr>
                    <th className='title'>출원 상표명</th>
                    <th>
                      {info?.mark_name_step1}
                      {info?.mark_name_step2 ? ` ${info?.mark_name_step2}` : ''}
                      {info?.mark_name_step3 ? ` ${info?.mark_name_step3}` : ''}
                      {info?.mark_name_step4 ? ` ${info?.mark_name_step4}` : ''}
                      {info?.mark_name_step5 ? ` ${info?.mark_name_step5}` : ''}
                    </th>
                    <th className='title'>등록일자</th>
                    <th>{info?.created_at?.split('T')[0]}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='title'>상표 유형</td>
                    <td colSpan={3}>{markTypeList[info?.mark_type]}상표</td>
                  </tr>
                  <tr>
                    <td className='title' rowSpan={info?.mark_classes?.length}>
                      상품/업종
                    </td>
                    <td colSpan={3}>
                      {info?.mark_classes ? info?.mark_classes[0] : ''}류 |{' '}
                      {info?.register_products
                        ? info?.register_products[0]
                        : ''}
                    </td>
                  </tr>
                  {info?.mark_classes?.length > 1
                    ? info?.mark_classes?.slice(1)?.map((mark_class, idx) => {
                        return (
                          <tr>
                            <td colSpan={3}>
                              {mark_class}류 | {info?.register_products[idx]}
                            </td>
                          </tr>
                        );
                      }, <></>)
                    : ''}
                </tbody>
              </table>
            </div>
            <h5>출원인 정보</h5>
            <div className='table-wrap applicant-info'>
              <table>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>이름</th>
                    <th>특허고객번호</th>
                    <th>지분</th>
                  </tr>
                </thead>
                <tbody>{renderApplicantInfo()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {alertBox.bool && (
        <CommonModal
          setModal={setAlertBox}
          modal={alertBox}
          okFn={() => {
            if (alert === 'logout') navigate('/');
            else return;
          }}
        />
      )}
    </>
  );
};

export default SelfApplicationDetail;
