import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SideBar from 'Components/SideBar';
import CommonModal from 'Components/CommonModal';
import Pagination from 'Components/Pagination';
import { getSelfList } from 'JS/API';
import { catchError, changeState } from 'JS/common';

const SelfApplication = () => {
  const [selfData, setSelfData] = useState();
  const [pageInfo, setPageInfo] = useState({
    page: 1,
    limit: 10,
    totalPage: 10,
  });
  const [searchData, setSearchData] = useState({
    markName: '',
    templateName: '',
    applicantName: '',
    startDate: '',
    endDate: '',
    markType: 'all',
  });
  const [alert, setAlert] = useState('');
  const [alertBox, setAlertBox] = useState({
    mode: '',
    context: '',
    bool: false,
  });

  let prevent = false;
  const today = new Date();
  const navigate = useNavigate();

  const getList = async () => {
    if (prevent) return;
    prevent = true;
    setTimeout(() => (prevent = false), 200);
    const result = await getSelfList(pageInfo, searchData);
    if (typeof result === 'object') {
      setSelfData(result?.data?.data);
      setPageInfo(prev => {
        const clone = { ...prev };
        clone.totalPage = result?.data?.meta?.total_page;
        return clone;
      });
    } else catchError(result, navigate, setAlertBox, setAlert);
  };

  useEffect(() => {
    getList();
  }, [pageInfo?.page]);

  return (
    <>
      <div className='container'>
        <SideBar />
        <div className='content-wrap self'>
          <div className='topBar'>
            <h2>SELF-APPLICATION</h2>
          </div>
          <div className='column search-wrap'>
            <div className='search-first'>
              <div>
                <span>출원상표명</span>
                <input
                  type='text'
                  placeholder='출원 상표명을 입력해 주세요.'
                  value={searchData?.markName}
                  onChange={e =>
                    changeState(setSearchData, 'markName', e.target.value)
                  }
                />
              </div>
              <div>
                <span>템플릿명</span>
                <input
                  type='text'
                  placeholder='템플릿명을 입력해 주세요.'
                  value={searchData?.templateName}
                  onChange={e =>
                    changeState(setSearchData, 'templateName', e.target.value)
                  }
                />
              </div>
            </div>
            <div className='search-two'>
              <div>
                <span>출원인</span>
                <input
                  type='text'
                  placeholder='출원인을 입력해 주세요.'
                  value={searchData?.applicantName}
                  onChange={e =>
                    changeState(setSearchData, 'applicantName', e.target.value)
                  }
                />
              </div>
              <div className='date'>
                <span>신청일</span>
                <input
                  type='date'
                  max={`${today.getFullYear()}-${
                    today.getMonth() + 1 < 10
                      ? `0${today.getMonth() + 1}`
                      : today.getMonth() + 1
                  }-${
                    today.getDate() + 1 < 10
                      ? `0${today.getDate()}`
                      : today.getDate()
                  }`}
                  value={searchData.startDate}
                  onChange={e =>
                    changeState(setSearchData, 'startDate', e.target.value)
                  }
                />
                <span>~</span>
                <input
                  type='date'
                  max={`${today.getFullYear()}-${
                    today.getMonth() + 1 < 10
                      ? `0${today.getMonth() + 1}`
                      : today.getMonth() + 1
                  }-${
                    today.getDate() + 1 < 10
                      ? `0${today.getDate()}`
                      : today.getDate()
                  }`}
                  value={searchData.endDate}
                  onChange={e =>
                    changeState(setSearchData, 'endDate', e.target.value)
                  }
                />
              </div>
            </div>
            <div className='search-three'>
              <div className='trade-mark-type'>
                <span>상표유형</span>
                <select
                  value={searchData.markType}
                  onChange={e =>
                    changeState(setSearchData, 'markType', e.target.value)
                  }>
                  <option value='all'>선택</option>
                  <option value='text'>문자상표</option>
                  <option value='figure'>도형상표</option>
                  <option value='compound'>복합상표</option>
                </select>
              </div>
              <div className='btn-wrap'>
                <button onClick={getList}>검색</button>
                <button
                  onClick={() => {
                    setSearchData({
                      markName: '',
                      templateName: '',
                      applicantName: '',
                      startDate: '',
                      endDate: '',
                      markType: 'all',
                    });
                    getList();
                  }}>
                  초기화
                </button>
              </div>
            </div>
          </div>
          <div className='table-wrap'>
            <table>
              <colgroup>
                <col width='5%' />
                <col width='20%' />
                <col width='20%' />
                <col width='5%' />
                <col width='15%' />
                <col width='15%' />
                <col width='20%' />
              </colgroup>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>템플릿명</th>
                  <th>출원상표명</th>
                  <th>상표유형</th>
                  <th>출원인</th>
                  <th>특허고객번호</th>
                  <th>등록일</th>
                </tr>
              </thead>
              <tbody>
                {selfData?.length > 0 ? (
                  selfData?.map(
                    (
                      {
                        id,
                        template_name,
                        mark_name,
                        mark_type,
                        applicant_name,
                        applicant_code,
                        created_at,
                      },
                      idx
                    ) => {
                      return (
                        <tr
                          key={id}
                          onClick={() => navigate(`/self-application/${id}`)}>
                          <td>{idx + 1}</td>
                          <td>{template_name}</td>
                          <td>{mark_name}</td>
                          <td>
                            {mark_type === 'TEXT'
                              ? '문자상표'
                              : mark_type === 'COMPOUND'
                              ? '복합상표'
                              : '도형상표'}
                          </td>
                          <td>{applicant_name?.split(',')[0]}</td>
                          <td>{applicant_code?.split(',')[0]}</td>
                          <td>{created_at?.replace('T', ' ')}</td>
                        </tr>
                      );
                    }
                  )
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
          <Pagination pageInfo={pageInfo} setPageInfo={setPageInfo} />
        </div>
      </div>
      {alertBox.bool && (
        <CommonModal
          setModal={setAlertBox}
          modal={alertBox}
          okFn={() => {
            if (alert === 'logout') navigate('/');
            else return;
          }}
        />
      )}
    </>
  );
};

export default SelfApplication;
