import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonModal from './CommonModal';
import { FaWindowClose } from 'react-icons/fa';
import { getAdminDetail, addAdmin, idDuplicateCheck, editAdmin } from 'JS/API';
import {
  outClick,
  changeState,
  commonModalSetting,
  regularExpression,
  catchError,
} from 'JS/common';

const AdminApplyModal = ({ setModal, mode, id }) => {
  const [alert, setAlert] = useState('');
  const [alertBox, setAlertBox] = useState({
    mode: '',
    context: '',
    bool: false,
  });
  const [idCheck, setIdCheck] = useState('');
  const [render, setRender] = useState(false);
  const [originInfo, setOriginInfo] = useState({});
  const [info, setInfo] = useState({
    user_id: '',
    password: '',
    name: '',
    email: '',
    admin_role: 'admin',
  });
  const navigate = useNavigate();
  let prevent = false;

  useEffect(() => {
    window.addEventListener('click', e => outClick(e, setModal));
  });

  //= EmptyInput 확인
  const checkInputVal = () => {
    if (mode === 'apply' && !idCheck)
      return commonModalSetting(
        setAlertBox,
        true,
        'alert',
        '아이디 중복확인을 해 주세요.'
      );
    else if (mode === 'apply' && info?.password.trim() === '')
      return commonModalSetting(
        setAlertBox,
        true,
        'alert',
        '비밀번호를 입력해 주세요.'
      );
    else if (
      info?.password !== 'samplePw' &&
      !regularExpression('pw', info?.password)
    )
      return commonModalSetting(
        setAlertBox,
        true,
        'alert',
        '비밀번호 형식이 잘못되었습니다.<br/>비밀번호는 특수문자를 포함하여<br/>8 ~ 20자리만 가능합니다.'
      );
    else if (mode === 'apply' && info?.name.trim() === '')
      return commonModalSetting(
        setAlertBox,
        true,
        'alert',
        '성명을 입력해 주세요.'
      );
    else if (info?.email.trim() === '')
      return commonModalSetting(
        setAlertBox,
        true,
        'alert',
        '이메일을 입력해 주세요.'
      );
    else if (!regularExpression('email', info?.email))
      return commonModalSetting(
        setAlertBox,
        true,
        'alert',
        '이메일의 형식이 잘못되었습니다.<br/>다시 확인해 주세요.'
      );
    else return true;
  };

  //= 아이디 중복체크
  const duplicateCheck = async () => {
    if (info?.user_id?.trim() === '')
      return commonModalSetting(
        setAlertBox,
        true,
        'alert',
        '아이디를 입력해 주세요.'
      );
    else if (!regularExpression('id', info?.user_id))
      commonModalSetting(
        setAlertBox,
        true,
        'alert',
        '아이디 형식이 잘못되었습니다.<br/>아이디는 4자리에서 30자리까지 가능합니다.'
      );
    else {
      const result = await idDuplicateCheck(info?.user_id);
      setRender(true);
      if (typeof result === 'object') setIdCheck(true);
      else setIdCheck(false);
    }
  };

  //= 관리자 수정
  const adminEdit = async () => {
    if (checkInputVal()) {
      const query = {};
      if (info.admin_role !== originInfo.admin_role)
        query.admin_role = info.admin_role;
      if (info.email !== originInfo.email) query.email = info.email;
      if (info.password !== 'samplePw') query.password = info.password;
      const result = await editAdmin(info.user_id, query);
      if (typeof result === 'object') {
        setAlert('completeEdit');
        commonModalSetting(
          setAlertBox,
          true,
          'alert',
          '수정이 완료되었습니다.'
        );
      } else return await catchError(result, navigate, setAlertBox, setAlert);
    }
  };

  //= 관리자 등록
  const applyAdmin = async () => {
    if (checkInputVal()) {
      const query = { ...info };
      const result = await addAdmin(query);
      if (typeof result === 'object') {
        setAlert('completeApply');
        commonModalSetting(
          setAlertBox,
          true,
          'alert',
          '등록이 완료되었습니다.'
        );
      } else return await catchError(result, navigate, setAlertBox, setAlert);
    }
  };

  const getOriginInfo = async () => {
    if (prevent) return;
    prevent = true;
    setTimeout(() => {
      prevent = false;
    }, 200);
    const result = await getAdminDetail(id);
    if (typeof result === 'object') {
      const { admin_role, email, name, user_id } = result?.data?.data;
      setInfo(prev => {
        const clone = { ...prev };
        clone.admin_role = admin_role;
        clone.email = email;
        clone.name = name;
        clone.user_id = user_id;
        clone.password = 'samplePw';
        return clone;
      });
      setOriginInfo(result?.data?.data);
    }
  };

  useEffect(() => {
    // console.log(info);
  }, [info]);

  useEffect(() => {
    if (mode === 'edit') getOriginInfo();
  }, []);

  return (
    <>
      <div className='modal-background'>
        <div className='modal applyAdmin'>
          <div className='topBar'>
            <h2>{mode === 'apply' ? '관리자 등록' : '관리자 수정'}</h2>
            <div onClick={() => setModal(false)}>
              <FaWindowClose />
            </div>
          </div>
          <div className='row infoInput'>
            <div className='column'>
              <div className='row id'>
                <span>아이디</span>
                {mode === 'edit' ? (
                  <span className='fixedInput'>{info?.user_id}</span>
                ) : (
                  <>
                    <input
                      type='text'
                      className='idInput'
                      value={info?.user_id}
                      placeholder='아이디를 입력해 주세요.'
                      onChange={e => {
                        setRender(false);
                        changeState(setInfo, 'user_id', e.target.value);
                      }}
                    />
                    <button className='duplicateCheck' onClick={duplicateCheck}>
                      중복체크
                    </button>
                  </>
                )}
              </div>
              {idCheck ? (
                <span className={`idCheck ${render ? 'active canUsed' : ''}`}>
                  사용할 수 있는 아이디입니다.
                </span>
              ) : (
                <span className={`idCheck ${render ? 'active cantUsed' : ''}`}>
                  사용할 수 없는 아이디입니다.
                </span>
              )}
              <div className='row'>
                <span>비밀번호</span>
                <input
                  type='password'
                  value={info?.password}
                  placeholder='비밀번호를 입력해 주세요.'
                  onChange={e =>
                    changeState(setInfo, 'password', e.target.value)
                  }
                />
              </div>
              <div className='row'>
                <span>권한</span>
                <select
                  value={info?.admin_role}
                  onChange={e =>
                    changeState(setInfo, 'admin_role', e.target.value)
                  }>
                  <option value='super_admin'>최상위 관리자</option>
                  <option value='admin'>관리자</option>
                </select>
              </div>
            </div>
            <div className='column'>
              <div className='row'>
                <span>성명</span>
                {mode === 'edit' ? (
                  <span className='fixedInput'>{info?.name}</span>
                ) : (
                  <input
                    type='text'
                    value={info?.name}
                    placeholder='성명을 입력해 주세요.'
                    onChange={e => changeState(setInfo, 'name', e.target.value)}
                  />
                )}
              </div>
              <div className='row'>
                <span>이메일</span>
                <input
                  type='text'
                  value={info?.email}
                  placeholder='이메일을 입력해 주세요.'
                  onChange={e => changeState(setInfo, 'email', e.target.value)}
                />
              </div>
            </div>
          </div>
          <div>
            <button
              className='applyBtn'
              onClick={mode === 'edit' ? adminEdit : applyAdmin}>
              {mode === 'edit' ? '수정' : '등록'}
            </button>
          </div>
        </div>
      </div>
      {alertBox.bool && (
        <CommonModal
          setModal={setAlertBox}
          modal={alertBox}
          okFn={() => {
            if (
              alert === 'completeApply' ||
              alert === 'completeDelete' ||
              alert === 'completeEdit'
            )
              setModal(false);
            else if (alert === 'logout') navigate('/');
            else return;
          }}
        />
      )}
    </>
  );
};

export default AdminApplyModal;
